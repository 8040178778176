import { type Alpine } from "alpinejs";

export function Money(Alpine: Alpine) {
  Alpine.directive(
    "money",
    (el, { expression, modifiers }, { evaluateLater, effect }) => {
      const [modLang, modCurrency] = modifiers;

      const isDecimal = modifiers.includes("decimal");
      const isShopify = modifiers.includes("shopify");

      const formatLang = isShopify ? window.Shopify.locale : modLang;
      const formatCurrency = isShopify
        ? window.Shopify.currency.active
        : modCurrency;

      const getValue = evaluateLater(expression);
      effect(() => {
        getValue((moneyValue: unknown) => {
          const formattedMoney = isDecimal
            ? Number(moneyValue)
            : Number(moneyValue) / 100;
          const formattedPrice = new Intl.NumberFormat(formatLang, {
            style: "currency",
            currency: formatCurrency,
          }).format(formattedMoney);

          el.innerText = formattedPrice;
        });
      });
    }
  );
}
