import * as Sentry from "@sentry/browser";
import { RechargeWorker } from "@/workers";
import type { AlpineComponent } from "alpinejs";
import { type Variant } from "@/vue/apps/product/types";

type UpgradeArgs = {
  variant: Variant;
  discountCode?: string;
  discountAmount?: number;
};

type SegmentTrackData = {
  plan_movement: string;
  former_plan_name: string;
  new_plan_name: string;
  former_plan_price: string;
  new_plan_price: string;
  origin_source: string;
};

type State = {
  loading: boolean;
  error: boolean;
  subscriptionId: string | undefined;
  rechargeCustomerId: string | undefined;
  formerPlanPrice: number;
  segmentAnalytics: (args: UpgradeArgs) => Promise<void>;
  upgradeMembership: (args: UpgradeArgs) => Promise<void>;
  performUpgrade: (variantId: string, discount: string) => Promise<void>;
  handleError: (error: any) => void;
};

export function Membership(): AlpineComponent<State> {
  const { subscription } = useSubscription();
  const { segmentTrack } = useAnalytics();

  return {
    // State
    loading: false,
    error: false,

    // Computed
    get subscriptionId() {
      return String(subscription.value?.subscription_id);
    },
    get rechargeCustomerId() {
      return String(subscription.value?.recharge_customer_id);
    },
    get formerPlanPrice() {
      return Number(subscription.value?.price) || 0;
    },

    // Methods
    async segmentAnalytics(args: UpgradeArgs) {
      const { variant, discountCode, discountAmount } = args;
      const formattedDiscountAmount = getFormattedDiscountAmount(
        discountCode,
        discountAmount
      );
      const newPlanPrice = calculateNewPlanPrice(
        variant.price,
        formattedDiscountAmount
      );

      const trackData: SegmentTrackData = {
        plan_movement: "upgrade",
        former_plan_name: "Unlimited Vet Advice",
        new_plan_name: variant.name,
        former_plan_price: formatPrice(this.formerPlanPrice),
        new_plan_price: formatPrice(newPlanPrice),
        origin_source: "Upgrade to annual",
      };

      await segmentTrack("Membership Updated", trackData);
    },

    async upgradeMembership(args: UpgradeArgs) {
      if (this.loading) return;

      this.loading = true;
      this.error = false;

      try {
        await this.performUpgrade(
          String(args.variant.id),
          args.discountCode ?? ""
        );
        await this.segmentAnalytics(args);

        redirectToAccount({
          annual_upgraded: "true",
          discount_amount:
            getFormattedDiscountAmount(
              args.discountCode,
              args.discountAmount
            )?.toString() ?? "",
          new_plan: getNewPlanType(args.variant),
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async performUpgrade(variantId: string, discount: string) {
      const result = await RechargeWorker.subscription.upgrade.$post({
        json: {
          variant_id: variantId,
          subscription_id: this.subscriptionId,
          rc_customer_id: this.rechargeCustomerId,
          discount,
        },
      });
      if (!result.ok) throw new Error("Upgrade failed");
    },

    handleError(error: any) {
      this.error = true;
      Sentry.captureException(error);
      console.error("Membership upgrade failed:", error);
    },
  };
}

// Improved helper functions
const getFormattedDiscountAmount = (
  discountCode?: string,
  discountAmount?: number
): number | undefined => {
  return discountCode && discountAmount ? discountAmount / 100 : undefined;
};

const calculateNewPlanPrice = (
  basePrice: number,
  discountAmount?: number
): number => {
  const discount = discountAmount ?? 0;
  const price = (basePrice - discount) / 100;

  return Math.ceil(price);
};

const formatPrice = (amount: number): string => {
  return `$${amount.toFixed(2)}`;
};

const getNewPlanType = (variant: Variant): string => {
  const types = [
    {
      type: "annual",
      name: "Annual",
    },
    {
      type: "biennial",
      name: "2-Year",
    },
  ];

  return (
    types.find((type) =>
      variant.name.toLowerCase().includes(type.name.toLowerCase())
    )?.type ?? ""
  );
};

const redirectToAccount = (params?: Record<string, string>): void => {
  const searchParams = new URLSearchParams(params);
  window.location.assign(`/account#/?${searchParams.toString()}`);
};
