import type { AlpineComponent } from "alpinejs";

type State = {
  loading: boolean;
  isOpen: boolean;
  selectedFilters: string[];
  selectedSorting: string;
  targetContainer: HTMLElement | null;
  targetSectionId: string | null;
  filteredData: FilteredItem[];
  convertParamsToFilters: () => void;
  applyFiltersAndUpdateView: () => void;
  getTargetContainerAndSectionId: () => void;
  fetchTemplate: (params: string) => Promise<string | null>;
  updateFilteredResults: (template: string | null, params: string) => void;
  getTemplateFromCache: (params: string) => string | null;
  resetAllFilters: () => void;
};

type FilteredItem = {
  url: string;
  html: string;
};

type Props = {
  containerId: string;
};

const NO_RESULTS_TEMPLATE = `
  <div class="p-5">
    <h6 class="text-lg">Oops, no products found.</h6>
    <p>Please update the filters to expand your search.</p>
  </div>`;

export function CollectionFiltering(props: Props): AlpineComponent<State> {
  const { containerId } = props;
  const parsedUrl = new URL(window.location.href);
  const params = new URLSearchParams(parsedUrl.search);

  return {
    loading: false,
    isOpen: false,
    targetContainer: null,
    targetSectionId: null,
    selectedFilters: [],
    selectedSorting: "",
    filteredData: [],
    async init() {
      this.convertParamsToFilters();
      this.getTargetContainerAndSectionId();

      this.$watch("selectedFilters", () => {
        this.applyFiltersAndUpdateView();
      });
      this.$watch("selectedSorting", () => {
        this.applyFiltersAndUpdateView();
      });
    },
    async fetchTemplate(params: string) {
      if (!this.targetSectionId) {
        console.error("No target section id found");
        return null;
      }
      const baseUrl = `${window.location.pathname}?section_id=${this.targetSectionId}`;
      try {
        this.loading = true;
        const response = await fetch(`${baseUrl}&${params}`);
        const data = await response.text();

        return data;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        this.loading = false;
      }
    },

    updateFilteredResults(template, params) {
      if (!this.targetContainer || !template?.length) {
        console.error("No target container or template found");
        return;
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(template, "text/html").body;
      const targetSection = doc.querySelector(containerId);

      if (!targetSection) {
        console.error("No target section found in template");
        return;
      }

      const resultsWrapper = targetSection.querySelector(`ul`);
      const newContent =
        !resultsWrapper || !resultsWrapper.children.length
          ? NO_RESULTS_TEMPLATE
          : targetSection.innerHTML;

      this.targetContainer.innerHTML = newContent;

      if (!this.getTemplateFromCache(params)) {
        this.filteredData.push({ url: params, html: newContent });
      }
    },
    convertParamsToFilters() {
      if (params?.size > 0) {
        for (const [key, value] of params.entries()) {
          if (key === "sort_by") {
            this.selectedSorting = value;
          }
          this.selectedFilters.push(`${key}=${value}`);
        }
      }
    },
    async applyFiltersAndUpdateView(): Promise<void> {
      const currentUrl = new URL(
        window.location.origin + window.location.pathname
      );
      const searchParams = new URLSearchParams(currentUrl.search);

      this.selectedFilters.forEach((param) => {
        const [key, value] = param.split("=");
        searchParams.append(key, value);
      });

      if (this.selectedSorting) {
        searchParams.set("sort_by", this.selectedSorting);
      }

      const params = searchParams.toString();
      const newUrl = `${window.location.pathname}?${params}`;

      const cachedTemplate = this.getTemplateFromCache(params);
      if (cachedTemplate && this.targetContainer) {
        this.targetContainer.innerHTML = cachedTemplate;
      } else {
        const template = await this.fetchTemplate(params);
        this.updateFilteredResults(template, params);
      }

      window.history.replaceState({}, "", newUrl);
    },
    getTargetContainerAndSectionId() {
      if (
        this.targetContainer ||
        !containerId ||
        !document.querySelector(containerId)
      ) {
        return console.error("No target container found");
      }
      this.targetContainer = document.querySelector(containerId);

      if (!this.targetContainer?.dataset.sectionId) {
        return console.error("No section id found on target container");
      }

      this.targetSectionId = this.targetContainer.dataset.sectionId;
    },

    getTemplateFromCache(params: string): string | null {
      return params && this.filteredData.length
        ? (this.filteredData.find((item) => item.url === params)?.html ?? null)
        : null;
    },
    resetAllFilters() {
      this.selectedFilters = [];
      this.selectedSorting = "";
    },
  };
}
