import type { CartItem } from "@/js/composables/useCart/types";

type ButtonType = "primary" | "secondary";

interface ButtonAction {
  label: string;
  action: () => void;
}

interface ButtonActions {
  primary?: ButtonAction;
  secondary?: ButtonAction;
}

interface ShowArgs {
  items: CartItem[];
  actions?: Partial<ButtonActions>;
  isPrescription?: boolean;
}

interface State {
  isOpen: boolean;
  items: CartItem[];
  actions: ButtonActions;
  isPrescription: boolean;
  dispatchAction(type: ButtonType): void;
  show(args: ShowArgs): void;
  hide(): void;
}

// Store
const ui: State = {
  isOpen: false,
  items: [],
  isPrescription: false,
  actions: {
    primary: {
      label: "View cart",
      action: () => window.location.assign("/cart"),
    },
    secondary: {
      label: "Add more products",
      action: () => ui.hide(),
    },
  },
  dispatchAction(type: ButtonType) {
    if (this.actions[type] === undefined) return;
    this.actions[type]!.action();
  },
  show({ items, actions = {}, isPrescription = false }: ShowArgs) {
    this.items = items;
    this.isOpen = true;
    this.isPrescription = isPrescription;

    // Merge new actions with existing ones
    const combinedActions = Object.fromEntries(
      Object.entries(actions).map(([type, action]) => [
        type,
        { ...this.actions[type as ButtonType], ...action },
      ])
    );

    this.actions = {
      ...this.actions,
      ...combinedActions,
    };
  },
  hide() {
    this.isOpen = false;
  },
};

export type { ButtonAction, ButtonActions, ButtonType, ShowArgs, State };
export { ui };
