import accordion from "@/js/components/accordion";
import cfForm from "@/js/components/cfForm";
import reviews from "@/js/components/reviews";
import specialists from "@/js/components/specialists";
import tableOfContents from "@/js/components/table-of-contents";
// @ts-expect-error
import { picoapp } from "picoapp";

const components = {
  specialists,
  reviews,
  cfForm,
  accordion,
  tableOfContents,
};

export default picoapp(components);
