import * as Sentry from "@sentry/browser";

const { customer, IS_STAGING } = useTheme();

const initSentryJS = () => {
  const client = Sentry.init({
    dsn: IS_STAGING
      ? import.meta.env.VITE_STAGING_SENTRY_DSN
      : import.meta.env.VITE_SENTRY_DSN,
    environment: IS_STAGING ? "staging" : "production",
    integrations: [Sentry.browserTracingIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event) => {
      if (customer) {
        event.user = { id: customer.id, email: customer.email };
      }
      return event;
    },
  });

  if (IS_STAGING && client) {
    console.info("✅ Sentry initialized in staging mode");
  }
};

export { initSentryJS };
