import * as Sentry from "@sentry/browser";
import { ShopifyWorker } from "@/workers/shopify/src/client";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import type { AlpineComponent } from "alpinejs";
import { initializeIterableClient, trackEvent } from "@/api/iterable";

dayjs.extend(utc);

// Constants
const MESSAGES = {
  EMPTY_EMAIL: "Email cannot be empty.",
  INVALID_EMAIL: "Invalid email format.",
  VALID_EMAIL: "Email is valid.",
  SUCCESS: "Thanks for subscribing!",
  PHONE_TAKEN:
    "Thanks for subscribing! Your phone number could not be added as it's already in use. Try another number.",
  ERROR: "Oops, something went wrong. Please try again.",
};

const COOKIE_NAMES = {
  SUBSCRIBED: "_dp_newsletter_subscribed",
  LAST_CLOSED: "_dp_newsletter_last_closed",
};

const TIMINGS = {
  MODAL_DELAY: 4000,
  CLOSE_DELAY: 2000,
};

const EVENTS = {
  SMS: "sms_opt_in",
  POP_UP: "pop_up_opt_in",
};

const REGEX = {
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  PHONE: /(\d{0,3})(\d{0,3})(\d{0,4})/,
  DIGITS: /\D/g,
  NUMBERS: /[\d]/,
};

// Types
type SubscriptionState = {
  isOpen: boolean;
  isSubscribed: boolean;
  loading: boolean;
  errorMessage: string;
  resultMessage: string;
  email: string;
  phoneNumber: string;
};

type State = SubscriptionState & {
  init: () => void;
  showModalWithDelay: () => void;
  initialization: () => void;
  subscribeUser: () => Promise<void>;
  closeModal: () => void;
  onPhoneKeyPress: (e: KeyboardEvent) => void;
};

// Pure utility functions
const validateEmail = (email: string) => {
  const trimmedEmail = email.trim();
  if (!trimmedEmail) return { isValid: false, message: MESSAGES.EMPTY_EMAIL };
  return REGEX.EMAIL.test(trimmedEmail)
    ? { isValid: true, message: MESSAGES.VALID_EMAIL }
    : { isValid: false, message: MESSAGES.INVALID_EMAIL };
};

const formatPhoneNumber = (value: string): string => {
  const digits = value.replace(REGEX.DIGITS, "").slice(0, 10);
  const match = digits.match(REGEX.PHONE);
  if (!match) return digits;
  return `(${match[1]})${match[2] ? ` ${match[2]}` : ""}${match[3] ? `-${match[3]}` : ""}`;
};

const getCleanPhoneNumber = (value: string): string =>
  value ? `+1${value.replace(REGEX.DIGITS, "")}`.slice(0, 12) : "";

const cookieUtils = {
  set: (name: string, value: string, expires?: number) =>
    Cookies.set(name, value, expires ? { expires } : undefined),
  get: (name: string) => Cookies.get(name),
  isSubscribed: () => Cookies.get(COOKIE_NAMES.SUBSCRIBED) === "true",
  shouldShowModal: () => {
    const lastClosed = Cookies.get(COOKIE_NAMES.LAST_CLOSED);
    if (!lastClosed) return true;
    const twoDaysAgo = dayjs().subtract(2, "day");
    return dayjs(lastClosed).isBefore(twoDaysAgo);
  },
  saveSubscriptionState: () =>
    cookieUtils.set(COOKIE_NAMES.SUBSCRIBED, "true", 365),
  saveModalClosed: () =>
    cookieUtils.set(COOKIE_NAMES.LAST_CLOSED, dayjs().toISOString()),
};

// Core tracking function
const trackIterableEvents = async (email: string, phoneNumber: string) => {
  await initializeIterableClient(email.trim());
  const cleanPhone = getCleanPhoneNumber(phoneNumber);

  if (cleanPhone.length) {
    await trackEvent({
      eventName: EVENTS.SMS,
      dataFields: { phoneNumber: cleanPhone, email },
    });
  }

  await trackEvent({
    eventName: EVENTS.POP_UP,
    dataFields: {
      phoneNumber: cleanPhone,
      email,
      source: window.location.href,
    },
  });
};

// Component
export function EmailSubscription(): AlpineComponent<State> {
  return {
    // State
    isOpen: false,
    isSubscribed: false,
    loading: false,
    errorMessage: "",
    resultMessage: "",
    email: "",
    phoneNumber: "",

    // Lifecycle
    init() {
      this.$watch("isOpen", (value) => {
        if (!value && !this.isSubscribed) {
          cookieUtils.saveModalClosed();
        }
      });

      this.$watch("phoneNumber", (value) => {
        this.phoneNumber = formatPhoneNumber(value);
      });

      this.initialization();
    },

    // Methods
    initialization() {
      this.isSubscribed = cookieUtils.isSubscribed();
      if (!this.isSubscribed && cookieUtils.shouldShowModal()) {
        this.showModalWithDelay();
      }
    },

    showModalWithDelay() {
      setTimeout(() => {
        this.isOpen = true;
      }, TIMINGS.MODAL_DELAY);
    },

    closeModal() {
      setTimeout(() => {
        this.isOpen = false;
      }, TIMINGS.CLOSE_DELAY);
    },

    onPhoneKeyPress(e: KeyboardEvent) {
      if (!REGEX.NUMBERS.test(e.key)) {
        e.preventDefault();
      }
    },

    async subscribeUser() {
      // Reset state
      this.errorMessage = "";
      this.resultMessage = "";
      this.isSubscribed = false;
      // Validate email
      const { isValid, message } = validateEmail(this.email);
      if (!isValid) {
        this.errorMessage = message;
        return;
      }

      // Prepare payload
      const cleanEmail = this.email.trim();
      const cleanPhone = getCleanPhoneNumber(this.phoneNumber);
      const payload = {
        email: cleanEmail,
        ...(cleanPhone && { phoneNumber: cleanPhone }),
      };

      // Submit subscription
      this.loading = true;
      try {
        const emailResult = await ShopifyWorker.customers.subscribe.$post({
          json: payload,
        });
        const response = await emailResult.json();

        // Track events
        await trackIterableEvents(cleanEmail, this.phoneNumber);

        // Handle response
        if (response.ok) {
          this.resultMessage = MESSAGES.SUCCESS;
          this.isSubscribed = true;
          cookieUtils.saveSubscriptionState();
        } else {
          this.errorMessage = MESSAGES.ERROR;
        }
      } catch (error: unknown) {
        this.errorMessage = MESSAGES.ERROR;
        if (error instanceof Error) {
          Sentry.captureException(error);
        }
      } finally {
        this.loading = false;
        if (!this.errorMessage) {
          this.closeModal();
        }
      }
    },
  };
}
