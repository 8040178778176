import * as Sentry from "@sentry/browser";
import type { AlpineComponent } from "alpinejs";
import Cookies from "js-cookie";
import type { ButtonActions, State as UIStore } from "@/alpine/stores/ui";
import type { State as CartStore } from "@/alpine/stores/cart/index";
import states from "@/data/states";
import type { CartItem } from "@/js/composables/useCart/types";
import { Product, Variant } from "@/vue/apps/product/types";
// @ts-ignore
import { formatMoney } from "@shopify/theme-currency";

type State = {
  label: string;
  value: string;
};

type Props = {
  product: Product;
  customerId: string;
};

async function displayCartDrawer({
  items,
  actions = {},
}: {
  items: CartItem[];
  actions?: ButtonActions;
}) {
  Alpine.store<CartStore>("cart").fetchCart();

  Alpine.store<UIStore>("ui").show({
    items,
    actions,
  });
}

type AlpineState = {
  loading: boolean;
  showStateError: boolean;
  states: State[];
  dogInsuranceVariant: Variant | null;
  catInsuranceVariant: Variant | null;
  selectedState: State | null;
  handleStateChange: (e: Event) => void;
  updateVariants: () => void;
  updateCTAs: () => void;
  getStateCode: (customerId: string) => Promise<string | null>;
  stateValidation: () => boolean;
  submitDogInsurance: () => Promise<void>;
  submitCatInsurance: () => Promise<void>;
  removeInsuranceFromCart: () => Promise<void>;
  addInsuranceToCart: (variant: Variant) => Promise<void>;
  handleAnalytics: (variant: Variant) => void;
};

export function InsuranceHandler({
  product,
  customerId,
}: Props): AlpineComponent<AlpineState> {
  const { removeCartItem, addItemToCart } = useCart();
  const { EMR_BASE_URL } = useTheme();

  const EMR_URL = `${EMR_BASE_URL}/CustomerV0`;

  return {
    loading: false,
    states,
    dogInsuranceVariant: null,
    catInsuranceVariant: null,
    showStateError: false,
    selectedState: null,
    async init() {
      if (customerId) {
        const stateCode = await this.getStateCode(customerId);
        this.selectedState =
          states.find((state) => state.value === stateCode) || null;
        this.updateVariants();
        this.updateCTAs();
      }
    },

    async getStateCode(customerId: string): Promise<string | null> {
      try {
        const response = await fetch(
          `${EMR_URL}/CustomerID?ShopifyCustomerID=${customerId}`
        );

        if (!response.ok) {
          console.error(
            `Failed to fetch state code. Status: ${response.status}`
          );
          return null;
        }

        const data = await response.json();
        return data?.StateCode || null;
      } catch (error) {
        console.error("Error fetching state code:", error);
        return null;
      }
    },
    handleStateChange(e: Event) {
      const target = e.target as HTMLSelectElement;
      this.selectedState =
        states.find((state) => state.label === target.value) || null;

      if (!this.selectedState) {
        this.showStateError = true;
        return;
      }

      this.updateVariants();
      this.updateCTAs();
      this.showStateError = false;
    },
    updateCTAs() {
      const dogInsuranceButton = this.$refs
        .dogInsuranceButton as HTMLButtonElement;
      const catInsuranceButton = this.$refs
        .catInsuranceButton as HTMLButtonElement;

      function formatPrice(price: number) {
        return `$${formatMoney(price, "{{amount}}").replace(".00", "")}/mo`;
      }

      dogInsuranceButton.innerHTML = `ADD dog INSURANCE - ${formatPrice(this.dogInsuranceVariant?.price ?? 0)}`;
      catInsuranceButton.innerHTML = `ADD cat INSURANCE - ${formatPrice(this.catInsuranceVariant?.price ?? 0)}`;
    },
    updateVariants() {
      // Group variants by pet type once
      const variantsByPet = product.variants.reduce(
        (acc: { cat: Variant[]; dog: Variant[] }, variant) => {
          const title = variant.title.toLowerCase();
          if (title.includes("cat")) acc.cat.push(variant);
          if (title.includes("dog")) acc.dog.push(variant);
          return acc;
        },
        { cat: [], dog: [] }
      );
      // Find state-specific variants if state is selected
      if (this.selectedState && this.selectedState.value) {
        this.dogInsuranceVariant =
          variantsByPet.dog.find((variant) =>
            variant.title.includes(this.selectedState?.value || "")
          ) ||
          variantsByPet.dog.find((v) => v.title.toLowerCase() === "dog") ||
          null;
        this.catInsuranceVariant =
          variantsByPet.cat.find((variant) =>
            variant.title.includes(this.selectedState?.value || "")
          ) ||
          variantsByPet.cat.find((v) => v.title.toLowerCase() === "cat") ||
          null;
        return;
      }

      // Default to generic variants
      this.dogInsuranceVariant =
        variantsByPet.dog.find((v) => v.title.toLowerCase() === "dog") || null;
      this.catInsuranceVariant =
        variantsByPet.cat.find((v) => v.title.toLowerCase() === "cat") || null;
    },
    stateValidation() {
      if (!this.selectedState) {
        this.showStateError = true;
        return false;
      }
      this.showStateError = false;
      return true;
    },
    async submitDogInsurance() {
      if (!this.stateValidation()) return;
      if (this.dogInsuranceVariant == null) return;

      await this.addInsuranceToCart(this.dogInsuranceVariant);
      this.handleAnalytics(this.dogInsuranceVariant);
    },
    async submitCatInsurance() {
      if (!this.stateValidation()) return;
      if (this.catInsuranceVariant == null) return;

      await this.addInsuranceToCart(this.catInsuranceVariant);
      this.handleAnalytics(this.catInsuranceVariant);
    },
    async removeInsuranceFromCart() {
      const cart = Alpine.store<CartStore>("cart");
      const insuranceItems =
        cart?.state?.items.filter(
          (item: CartItem) => item.product_type === "insurance"
        ) || [];

      for await (const item of insuranceItems) {
        await removeCartItem(item.id);
      }
    },
    async addInsuranceToCart(variant: Variant) {
      this.loading = true;
      const cartPayload = {
        items: [
          {
            id: variant.id,
            quantity: 1,
            selling_plan: variant.selling_plan_allocations[0]?.selling_plan_id,
            properties: {
              _keep: "true",
            },
          },
        ],
      };

      try {
        await this.removeInsuranceFromCart();
        const data = await addItemToCart(cartPayload);
        const matchingVariant = data.items.find(
          (item) => item.id === variant.id
        );
        if (matchingVariant) {
          displayCartDrawer({
            items: [
              {
                ...matchingVariant,
                // @ts-ignore
                featured_image: {
                  url: variant.featured_image.src,
                  alt: variant.featured_image.alt ?? "",
                },
              },
            ],
          });
        }
      } catch (error: any) {
        Sentry.captureException(error);
      } finally {
        this.loading = false;
      }
    },

    handleAnalytics(variant: Variant) {
      const { segmentTrack } = useAnalytics();
      segmentTrack("Product Added", {
        cart_id: Cookies.get("cart"),
        name: product.title,
        variant: variant.title,
        variant_id: variant.id,
        product_id: String(product.id),
        price: formatMoney(variant.price, "{{amount}}"),
        currency: "USD",
        quantity: 1,
        sku: variant.sku,
        url: window.location.href,
        value: formatMoney(variant.price, "{{amount}}"),
        product_type: product.type,
        source: "monthly insurance",
      });
    },
  };
}
