import plur from "plur";
import { type Alpine } from "alpinejs";

export function Plur(Alpine: Alpine) {
  Alpine.magic(
    "plur",
    () =>
      (word: string, count = 1) =>
        plur(word, count)
  );
}
