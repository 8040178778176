import { useUrlSearchParams } from "@vueuse/core";
import useAnalytics from "@composables/useAnalytics";

interface Collection {
  title: string;
  handle: string;
  products: any[];
}

interface FilterItem {
  label: string;
  value: string;
  active: boolean;
}

interface Filters {
  pet: FilterItem[];
  drug: FilterItem[];
  collection: FilterItem[];
  brands: FilterItem[];
}

interface CollectionsState {
  filter: boolean;
  loading: boolean;
  isNoResult: boolean | null;
  activeFilters: string[];
  filters: Filters;
  collections: Collection[];
  $refs: { [key: string]: HTMLElement };
  $watch: (key: string, callback: () => void) => void;
}

export function Collections(state: CollectionsState) {
  const params = useUrlSearchParams("history");

  return {
    ...state,
    filter: false,
    loading: true,
    isNoResult: null as boolean | null,
    activeFilters: params.filterBy?.length
      ? String(params.filterBy).split(",")
      : [],
    filters: {
      pet: [
        {
          label: "Cat",
          value: "cat",
          active: false,
        },
        {
          label: "Dog",
          value: "dog",
          active: false,
        },
      ],
      drug: [
        {
          label: "Non-prescription",
          value: "OTC",
          active: false,
        },
        {
          label: "Prescription",
          value: "Rx",
          active: false,
        },
      ],
      collection: [] as FilterItem[],
      brands: [] as FilterItem[],
    },
    initializeFilters(
      filterType: keyof Filters,
      filterCondition: (collection: FilterItem) => boolean
    ) {
      this.filters[filterType] = this.collections
        .map((collection: Collection) => ({
          label: collection.title,
          value: collection.handle,
          active: false,
        }))
        // Hide gift cards collection
        .filter(
          (collection: FilterItem) =>
            !collection.label.toLowerCase().includes("gift cards")
        )
        .filter(filterCondition);
    },
    initCollections() {
      this.initializeFilters(
        "collection",
        (collection) => !collection.value.toLowerCase().includes("brands-")
      );
    },
    initBrands() {
      this.initializeFilters("brands", (collection) =>
        collection.value.toLowerCase().includes("brands-")
      );
    },

    setHeader(arr: any[]) {
      if (!arr.length) return;
      arr.forEach((el, i) => {
        if (i === 0) {
          this.$refs.filterTitle.innerText = el.title;
          this.$refs[el.handle]?.querySelector("h2")?.classList.add("hidden");
        } else {
          this.$refs[el.handle]
            ?.querySelector("h2")
            ?.classList.remove("hidden");
        }
      });
    },
    initFilters() {
      const filters = String(params.filterBy)?.split(",");

      if (!filters?.length) return;

      Object.keys(this.filters).forEach((key) => {
        this.filters[key as keyof Filters] = this.filters[
          key as keyof Filters
        ].map((filter) => {
          if (!filters.includes(filter.value)) return filter;

          return {
            ...filter,
            active: true,
          };
        });
      });

      this.setHeader(this.collections);
    },
    resetFilters() {
      for (const key in this.filters) {
        this.filters[key as keyof Filters] = this.filters[
          key as keyof Filters
        ].map((filter) => ({
          ...filter,
          active: false,
        }));
      }
    },
    resetCategories() {
      this.collections.forEach(({ handle }) =>
        this.$refs[handle].classList.remove("hidden")
      );
      this.setHeader(this.collections);
    },
    resetProducts() {
      this.collections.forEach(({ products }) => {
        products.forEach((product) => (product.hidden = null));
      });
    },
    getActiveFilters(types: string[]) {
      if (!types?.length) throw new Error("filter types are required");

      return types
        .map((key) => this.filters[key as keyof Filters])
        .flat()
        .filter((el) => el.active)
        .map(({ value }) => value);
    },
    getChangedFilter(array1: string[], array2: string[]) {
      const uniqueInArray1 = array1.filter((item) => !array2.includes(item))[0];
      const uniqueInArray2 = array2.filter((item) => !array1.includes(item))[0];

      return uniqueInArray1 || uniqueInArray2;
    },
    trackFilter(action: string, name: string) {
      if (name == "undefined") return;
      useAnalytics().segmentTrack("Product List Filtered", {
        filter_action: action,
        filter_name: name,
      });
    },
    saveFilters() {
      const updatedFilters = this.getActiveFilters([
        "drug",
        "pet",
        "collection",
        "brands",
      ]);

      const currentFilters =
        params.filterBy !== "" ? String(params.filterBy).split(",") : [];

      if (updatedFilters.length !== currentFilters.length) {
        const action =
          updatedFilters.length > currentFilters.length ? "added" : "removed";
        const name = this.getChangedFilter(currentFilters, updatedFilters);
        this.trackFilter(action, name);
      }

      params.filterBy = updatedFilters.toString();
    },
    filterByCollectionAndBrand() {
      const activeCollections = this.filters.collection
        .filter(({ active }) => active)
        .map(({ value, label }) => ({ handle: value, title: label }));

      const activeBrands = this.filters.brands
        .filter(({ active }) => active)
        .map(({ value, label }) => ({ handle: value, title: label }));

      if (activeCollections.length || activeBrands.length) {
        this.setHeader([...activeCollections, ...activeBrands]);
      }

      !activeCollections.length && !activeBrands.length
        ? this.resetCategories()
        : this.collections.forEach(({ handle }) => {
            activeCollections?.map(({ handle }) => handle).includes(handle) ||
            activeBrands?.map(({ handle }) => handle).includes(handle)
              ? this.$refs[handle]?.classList.remove("hidden")
              : this.$refs[handle]?.classList.add("hidden");
          });
    },
    filterByDrugType() {
      const activeFilters = this.getActiveFilters(["drug"]);

      if (!activeFilters.length) {
        this.getActiveFilters(["pet"]).length && this.filterByPetType();

        return;
      }

      this.collections.forEach(({ products }) => {
        products.forEach((product) => {
          product.hidden = !activeFilters.some((tag) => product.type === tag);
        });
      });
    },
    filterByPetType() {
      const activeFilters = this.getActiveFilters(["pet"]);

      if (!activeFilters.length) {
        this.getActiveFilters(["drug"]).length && this.filterByDrugType();

        return;
      }

      this.collections.forEach(({ products }) => {
        products.forEach((product) => {
          const includes = product.tags.filter((tag: string) =>
            activeFilters.includes(tag)
          );

          includes.length ? (product.hidden = false) : (product.hidden = true);
        });
      });
    },
    filterProducts() {
      const activeFilters = this.getActiveFilters(["pet", "drug"]);

      if (!activeFilters.length) return this.resetProducts();
    },
    watchProducts() {
      this.collections.forEach(({ handle, products }) => {
        products.forEach((product) =>
          product.hidden
            ? this.$refs[`${handle}-${product.id}`]?.classList.add("hidden")
            : this.$refs[`${handle}-${product.id}`]?.classList.remove("hidden")
        );
      });
    },
    checkIfNoResults() {
      const activeCollections = this.filters.collection
        .filter(({ active }) => active)
        .map(({ value }) => value);

      if (!activeCollections.length) {
        this.isNoResult = false;

        return;
      }

      const hasActiveProducts = !!this.collections.filter((collection) => {
        if (!activeCollections.includes(collection.handle)) return false;

        return collection.products.some(({ hidden }) => !hidden);
      }).length;

      this.isNoResult = !hasActiveProducts;
    },
    init() {
      this.loading = true;
      this.initCollections();
      this.initBrands();
      this.$watch("filters.pet", () => this.filterByPetType.bind(this)());
      this.$watch("filters.drug", () => this.filterByDrugType.bind(this)());
      this.$watch("filters.collection", () => {
        this.filterByCollectionAndBrand.bind(this)();
        this.checkIfNoResults.bind(this)();
      });
      this.$watch("filters.brands", () => {
        this.filterByCollectionAndBrand.bind(this)();
        this.checkIfNoResults.bind(this)();
      });
      this.$watch("filters", () => {
        this.filterProducts.bind(this)();
        this.saveFilters.bind(this)();
      });
      this.$watch("collections", () => {
        this.watchProducts.bind(this)();
        this.checkIfNoResults.bind(this)();
      });
      this.initFilters();
      this.loading = false;
    },
  };
}
